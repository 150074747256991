import React from "react"
import { graphql } from "gatsby"
import TheaterVideo from "@components/Theater/TheaterVideo"
import { Button } from "../components/Button"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import { getPostObj } from "../utils/utils"
import { Text } from "../components/Core"
import SEO from "../components/seo"

const PatientInstructionsPage = ({ pageContext, location, data }) => {
  const postObj = getPostObj(
    pageContext,
    data.allUniquePagesJson.nodes[0],
    data.allSpanishUniquePagesJson.nodes[0]
  )
  const { post, language } = postObj
  const translations = data.translations.nodes[0]

  let instructionsData

  switch (language) {
    case "en":
      instructionsData = data.allInstructionsJson.nodes
      break
    case "es":
      instructionsData = data.allSpanishInstructionsJson.nodes
      break
    default:
      instructionsData = data.allInstructionsJson.nodes
  }

  function sortArchive(archiveArray) {
    archiveArray.sort((a, b) => (a.order > b.order ? 1 : -1))
  }

  sortArchive(instructionsData)

  const instructionsPre = instructionsData.map(instruction => {
    if (instruction.preOrPost === "pre") {
      return (
        <InstructionItem
          language={instruction.language || language}
          translations={translations}
          preOrPost={instruction.preOrPost}
          url={instruction.title}
          name={instruction.name}
          youtube={instruction.youtube}
          instructionBlockHeading={instruction.instructionBlockHeading}
        />
      )
    }
  })

  const instructionsPostsWithVideo = instructionsData.map(instruction => {
    if (instruction.preOrPost === "post" && instruction.youtube) {
      return (
        <InstructionItem
          language={instruction.language || language}
          translations={translations}
          preOrPost={instruction.preOrPost}
          url={instruction.title}
          name={instruction.name}
          youtube={instruction.youtube}
          instructionBlockHeading={instruction.instructionBlockHeading}
        />
      )
    }
  })

  const instructionsPostsNoVideo = instructionsData.map(instruction => {
    if (instruction.preOrPost === "post" && !instruction.youtube) {
      return (
        <InstructionItem
          language={instruction.language || language}
          translations={translations}
          preOrPost={instruction.preOrPost}
          url={instruction.title}
          name={instruction.name}
          youtube={instruction.youtube}
          instructionBlockHeading={instruction.instructionBlockHeading}
        />
      )
    }
  })

  return (
    <SharedStateProvider>
      <Layout language="en">
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
          lang={language}
        />
        <div className="joshua-tree-content instructions-query">
          <div className="columns top-section text-section">
            <div className="column">
              <div className="columns is-mobile">
                <div className="column is-4" />
                <div className="column">
                  <div>
                    <h1 style={{ marginTop: 0 }}>{post.heading}</h1>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column is-4" />
                <div className="column is-16">
                  <Text text={post.topBlurb} />
                </div>
                <div className="column is-4" />
              </div>
            </div>
          </div>
          <section className="body-sections section instructions-body">
            <div className="columns operative-text-section">
              <div className="column">
                <div className="columns">
                  <div className="column is-4" />
                  <div className="column pre-post-text">
                    <Text as="h4" text={post.preOpSmallHeading} />
                    <Text as="h3" text={post.preOpBigHeading} />
                    <Text text={post.preOpBlurb} />
                  </div>
                  <div className="column is-6" />
                </div>
              </div>
            </div>
            <div className="pre-op-instructions-wrapper">{instructionsPre}</div>
            <div className="columns operative-text-section">
              <div className="column">
                <div className="columns">
                  <div className="column is-4" />
                  <div className="column pre-post-text">
                    <Text as="h4" text={post.postOpSmallHeading} />
                    <Text as="h3" text={post.postOpBigHeading} />
                    <Text text={post.postOpBlurb} />
                  </div>
                  <div className="column is-6" />
                </div>
              </div>
            </div>
            <div className="post-op-instructions-wrapper">
              {instructionsPostsWithVideo}
              {instructionsPostsNoVideo}
            </div>
            {/* <div>
              <HeadingParagraphButton
                sideColumnIs={4}
                heading={post.getStarted.heading}
                paragraph={post.getStarted.blurb}
                buttonText={post.getStarted.buttonText}
                buttonUrl={post.getStarted.href}
              />
            </div> */}
          </section>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

function InstructionItem(props) {
  const { youtube, translations } = props

  let subheading = translations.instructions[props.preOrPost][props.language]

  return (
    <div className="columns instructions-block-wrapper">
      <div className="column is-3" />
      <div className="column instructions-block">
        <div className="left-is-1 right-is-1">
          <div className="columns is-vcentered">
            <div className="column">
              <Text
                as="h4"
                className="operative-subheading"
                text={subheading}
              />
              <Text
                as="h5"
                className="operative-heading"
                text={props.instructionBlockHeading}
              />
            </div>
            <div className="column">
              <div className="button-group-wrapper is-centered-mobile">
                <div className="button-group mt-0--desktop mt-1--mobile">
                  {youtube && (
                    <TheaterVideo
                      videoUrl={`http://youtube.com/watch?v=${youtube}`}
                      playing
                      onlyButton
                      language={props.language}
                      buttonClass="contained"
                    />
                  )}

                  <Button
                    contained
                    buttonText={translations.readMore[props.language]}
                    href={`/${props.url}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="column is-3" />
    </div>
  )
}

export const pageQuery = graphql`
  query instructionsEs($title: String!) {
    allInstructionsJson {
      nodes {
        title
        name
        preOrPost
        youtube
        instructionBlockHeading
        order
      }
    }
    allSpanishInstructionsJson {
      nodes {
        title
        name
        preOrPost
        youtube
        instructionBlockHeading
        order
      }
    }
    allInstructionsJson {
      nodes {
        title
        name
        preOrPost
        youtube
        instructionBlockHeading
        order
      }
    }
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        metaTitle
        metaDescription
        heading
        topBlurb
        preOpBlurb
        postOpBlurb
        preOpSmallHeading
        preOpBigHeading
        postOpBigHeading
        postOpSmallHeading
        getStarted {
          blurb
          buttonText
          heading
          href
        }
      }
    }
    allSpanishUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        metaTitle
        metaDescription
        heading
        topBlurb
        preOpBlurb
        postOpBlurb
        preOpSmallHeading
        preOpBigHeading
        postOpBigHeading
        postOpSmallHeading
        getStarted {
          blurb
          buttonText
          heading
          href
        }
      }
    }
    translations: allTranslationsJson {
      nodes {
        instructions {
          post {
            en
            es
          }
          pre {
            en
            es
          }
        }
        readMore {
          en
          es
        }
      }
    }
  }
`

export default PatientInstructionsPage
